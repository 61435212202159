<template>
  <div class="flex flex-row">
    <div class="flex flex-col flex-1 card">
      <h1 class="text-2xl font-semibold text-gray-900">Wachtwoord wijzigen</h1>
      <form class="flex flex-col space-y-3 lg:space-y-4 items-start" action="#" method="PATCH" @submit.prevent.stop="submitPasswordChange">
        <UIInput
          v-model="form.password"
          id="password"
          name="password"
          type="password"
          class="w-96"
          autocomplete="current-password"
          placeholder="Wachtwoord ..."
          label="Huidig Wachtwoord"
          required=""
        />
        <UIInput
          v-model="form.newPassword"
          id="password"
          name="password"
          type="password"
          class="w-96"
          autocomplete="new-password"
          placeholder="Wachtwoord ..."
          label="Nieuw Wachtwoord"
          required=""
        />
        <UIInput
          v-model="form.passwordConfirm"
          id="password"
          name="password"
          type="password"
          class="w-96"
          autocomplete="new-password"
          placeholder="Wachtwoord ..."
          label="Bevestig Nieuw Wachtwoord"
          required=""
        />

        <button type="submit" class="btn success">Opslaan</button>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'

import useAuthLogout from '@/hooks/useAuthLogout'
import useApi from '@/hooks/useApi'
import UIInput from '@/components/UI/Input/Input'

const api = useApi()
const store = useStore()

const form = ref({
  password: process.env.VUE_APP_AUTH_PASSWORD_DEVELOPMENT,
  newPassword: process.env.VUE_APP_AUTH_PASSWORD_DEVELOPMENT,
  passwordConfirm: process.env.VUE_APP_AUTH_PASSWORD_DEVELOPMENT,
  dashboard: true,
  email: store.state.profile.email,
})

const logout = useAuthLogout()

const submitPasswordChange = () => api('API_AUTH_PASSWORD_CHANGE', form).then(() => {
  alert('Wachtwoord aangepast, je word zo dadelijk uitgelogd.')
  logout()
})
</script>
